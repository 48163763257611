.background-1 {
  animation: background-1-animation 8s infinite;
  background-image: linear-gradient(165deg, hsl(178, 100%, 44%), hsl(209, 100%, 44%));
  border-radius: 5px;
}
.background-1:before {
  content: "";
  position: absolute;
  top: 0.7rem;
  left: 0.6rem;
  bottom: 0.7rem;
  right: 0.6rem;
  background-image: linear-gradient(165deg, hsl(178, 100%, 44%), hsl(209, 100%, 44%));
  filter: blur(35px);
}
.background-2 {
  animation: background-2-animation 8s infinite;
  background-image: linear-gradient(165deg, hsl(0, 84%, 61%), hsl(47, 95%, 57%));
  border-radius: 5px;
}

.background-2:before {
  content: "";
  position: absolute;
  top: 0.7rem;
  left: 0.6rem;
  bottom: 0.7rem;
  right: 0.6rem;
  background-image: linear-gradient(165deg, hsl(0, 84%, 61%), hsl(47, 95%, 57%));
  filter: blur(35px);
}
.background-3 {
  animation: background-3-animation 8s infinite;
  background-image: linear-gradient(165deg, hsl(330, 100%, 50%), hsl(270, 67%, 46%));
  border-radius: 5px;
}

.play-button-container {
  position: relative;
  padding: 0;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: transparent;
  transition: box-shadow 150ms;
  min-width: 326px;
  min-height: 64px;
}

.background-3:before {
  content: "";
  position: absolute;
  top: 0.7rem;
  left: 0.6rem;
  bottom: 0.7rem;
  right: 0.6rem;
  background-image: linear-gradient(165deg, hsl(330, 100%, 50%), hsl(270, 67%, 46%));
  filter: blur(35px);
}

@keyframes background-1-animation {
  0%,
  16.667%,
  to {
    opacity: 1;
  }

  33%,
  83.333% {
    opacity: 0;
  }
}

@keyframes background-2-animation {
  0%,
  16.667%,
  66.667%,
  to {
    opacity: 0;
  }

  33.333%,
  50% {
    opacity: 1;
  }
}

@keyframes background-3-animation {
  0%,
  50%,
  to {
    opacity: 0;
  }

  66.667%,
  83.333% {
    opacity: 1;
  }
}
